const faIcons = [
  'circle',
  'address-book',
  'address-card',
  'adjust',
  'ambulance',
  'anchor',
  'angle-double-down',
  'angle-double-left',
  'angle-double-right',
  'angle-double-up',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'apple-alt',
  'archive',
  'archway',
  'arrow-alt-circle-down',
  'arrow-alt-circle-left',
  'arrow-alt-circle-right',
  'arrow-alt-circle-up',
  'arrow-circle-down',
  'arrow-circle-left',
  'arrow-circle-right',
  'arrow-circle-up',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'arrows-alt',
  'arrows-alt-h',
  'arrows-alt-v',
  'assistive-listening-systems',
  'asterisk',
  'at',
  'atlas',
  'award',
  'baby',
  'baby-carriage',
  'backspace',
  'backward',
  'bahai',
  'balance-scale',
  'ban',
  'band-aid',
  'baseball-ball',
  'basketball-ball',
  'bed',
  'beer',
  'bicycle',
  'biking',
  'binoculars',
  'blind',
  'bolt',
  'bong',
  'book',
  'book-medical',
  'book-open',
  'book-reader',
  'box-open',
  'briefcase',
  'briefcase-medical',
  'broadcast-tower',
  'building',
  'bus',
  'bus-alt',
  'camera',
  'camera-retro',
  'campground',
  'car',
  'car-alt',
  'car-side',
  'caravan',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-square-down',
  'caret-square-left',
  'caret-square-right',
  'caret-square-up',
  'caret-up',
  'carrot',
  'cart-arrow-down',
  'cart-plus',
  'cash-register',
  'check',
  'check-circle',
  'check-square',
  'chevron-circle-down',
  'chevron-circle-left',
  'chevron-circle-right',
  'chevron-circle-up',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'city',
  'clinic-medical',
  'clock',
  'cocktail',
  'code-branch',
  'coffee',
  'cog',
  'cogs',
  'comment',
  'comment-alt',
  'compass',
  'compress',
  'compress-alt',
  'compress-arrows-alt',
  'concierge-bell',
  'credit-card',
  'cross',
  'crosshairs',
  'crow',
  'deaf',
  'directions',
  'dog',
  'edit',
  'envelope',
  'euro-sign',
  'exclamation',
  'exclamation-circle',
  'exclamation-triangle',
  'expand',
  'eye',
  'eye-slash',
  'faucet',
  'female',
  'file',
  'fire-alt',
  'fish',
  'flag',
  'flask',
  'gas-pump',
  'gavel',
  'graduation-cap',
  'h-square',
  'hammer',
  'hiking',
  'home',
  'hospital',
  'hospital-symbol',
  'hotel',
  'hourglass-half',
  'house-damage',
  'house-user',
  'image',
  'industry',
  'info',
  'info-circle',
  'landmark',
  'laptop-house',
  'leaf',
  'life-ring',
  'lightbulb',
  'location-arrow',
  'lock',
  'long-arrow-alt-down',
  'long-arrow-alt-left',
  'long-arrow-alt-right',
  'long-arrow-alt-up',
  'male',
  'map',
  'map-marked',
  'map-marked-alt',
  'map-marker',
  'map-marker-alt',
  'map-pin',
  'map-signs',
  'marker',
  'medal',
  'medkit',
  'microphone',
  'microscope',
  'mobile-alt',
  'money-bill',
  'motorcycle',
  'mountain',
  'mouse-pointer',
  'mug-hot',
  'music',
  'paint-brush',
  'palette',
  'paper-plane',
  'parking',
  'paw',
  'pen',
  'phone-alt',
  'plane',
  'plug',
  'plus',
  'plus-circle',
  'plus-square',
  'podcast',
  'question',
  'question-circle',
  'radiation',
  'recycle',
  'road',
  'running',
  'satellite-dish',
  'search',
  'search-location',
  'seedling',
  'share-alt',
  'ship',
  'shopping-basket',
  'shopping-cart',
  'shuttle-van',
  'skiing',
  'snowflake',
  'sort-down',
  'square',
  'square-full',
  'star',
  'star-and-crescent',
  'star-of-david',
  'star-of-life',
  'stethoscope',
  'sticky-note',
  'store',
  'store-alt',
  'street-view',
  'subway',
  'suitcase',
  'suitcase-rolling',
  'sun',
  'swimmer',
  'syringe',
  'tag',
  'taxi',
  'temperature-high',
  'temperature-low',
  'thumbs-down',
  'thumbs-up',
  'thumbtack',
  'times',
  'times-circle',
  'tint',
  'tint-slash',
  'toolbox',
  'tools',
  'tractor',
  'train',
  'tram',
  'trash-alt',
  'tree',
  'trophy',
  'truck',
  'truck-moving',
  'tshirt',
  'tv',
  'umbrella',
  'umbrella-beach',
  'university',
  'user-circle',
  'user-friends',
  'user-graduate',
  'user-md',
  'utensils',
  'vial',
  'video',
  'walking',
  'water',
  'weight-hanging',
  'wheelchair',
  'wifi',
  'wine-glass-alt',
  'wrench'
];

export default faIcons;
